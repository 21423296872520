export const LOCAL_STORAGE = "local-storage";
export const SESSION_STORAGE = "session-storage";

export const LS_DOMAIN_AND_LOCKER_BANK = "DnLB";
export const LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS = "TALBSet";
export const LS_CUSTOMER = "CsMr";
export const LS_PAGE_FLOW = "PgFlw";
export const LS_GUEST_USER = "gUSR";
export const LS_SELECTED_LANGUAGE = "selectedLanguage";
export const LB_LOCATION = "lBLocation";
export const LB_NAME = "lBCName";
export const TXN_ID = "txnId";
export const USER_INFO = "UIn";
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const ACCESS_TOKEN_EXPIRY_TIME = 'access_token_expiry_time';
export const REFRESH_TOKEN_EXPIRY_TIME = 'refresh_token_expiry_time';
export const OPERATION_ID = 'operation_id';
export const IS_LOGGED_IN = 'isLoggedIn';
export const PAYMENT_REFERENCE_ID = 'payment_reference_id';
export const PAYMENT_GATEWAY_REFERENCE ="payment_gateway_reference";
export const SELECTED_EQUPMNENT = 'selected_equipment';
export const SS_REDIRECTED_FROM_LOGIN = "redirected-from-login";
export const SS_PAYMENT_GATEWAY = 'PgWay';
export const SS_PAYMENT_GATEWAY_ACCESS_KEY = 'pGaKey';