import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment-timezone';
import {Box,Typography,Grid, Divider,useTheme} from '@mui/material';
import CircularProgressLoader from "../components/CircularProgressLoader";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BarcodeIcon, ButtonFloatingIcon, SearchIcon } from '../assets/constants/Icons';
import ButtonComponent from "../components/ButtonComponent";
import dashIcon from '../assets/images/dash_icon.png';
import CustomSearchBar from '../components/CustomSearchBar';
import { LockerItemWithDetails } from '../components/LockerItemWithDetails';
import { BadgeComponent } from '../components/BadgeComponent';
import { getSecureItemFromSpecificStorage, storeSecureItemInSpecifiedStorage } from '../lib/BrowserStorageAccessMiddleware';
import { LOCAL_STORAGE, SESSION_STORAGE, LS_DOMAIN_AND_LOCKER_BANK, LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS, USER_INFO, SS_PAYMENT_GATEWAY, SS_PAYMENT_GATEWAY_ACCESS_KEY} from '../assets/constants/BrowserStorageKeys';
import { getAssignedLockerList, openLocker, releaseLockerOfPickUpUser, getAvailableLockerSizesWithUnits, reserveLocker, overduePayment, fetchPaymentStatus, cancelDropOffTransaction } from '../api/api';
import { DYNAMIC_USE_CASE, LUGGAGE_USE_CASE, RETURN, SUCCESS, TOAST_SUCCESS, TOAST_ERROR, ERROR, PENDING, REDIRECT, BUTTON_PRIMARY, BUTTON_WARNING } from '../assets/constants/Constants';
import {HOME, LANDING, PAYMENT_GATEWAY, LOCKER_SIZE_SELECTION, LOCKER_UNIT_SELECTION, OPEN_LOCKER, LOCKER_OPENED} from '../assets/constants/PageList';
import { devConsoleLog, getPathForNextPage, convertDurationStringToMilliseconds, convertMillisecondsToSpecifiedTimeFormat, extractPageNumber } from '../lib/Utils';
import { APP_RICH_BLACK_COLOR, APP_WHITE_COLOR } from '../assets/constants/Colors';
import CustomDialog from '../components/CustomDialog';
import ShowToast from '../components/ToastComponent';
import { prePaymentRegistration } from '../lib/BackendUtils';

const MyLockeListPage = () => {
    const history = useHistory();
    const { t } = useTranslation();
    moment.tz.setDefault('Asia/Kolkata');
    const lockerBankId = getSecureItemFromSpecificStorage(LOCAL_STORAGE, LS_DOMAIN_AND_LOCKER_BANK)?.lockerBankId;
    const settings = getSecureItemFromSpecificStorage(LOCAL_STORAGE,LS_TENANT_ASSIGNMENT_LOCKER_BANK_SETTINGS)
    const userId = getSecureItemFromSpecificStorage(LOCAL_STORAGE, USER_INFO)?.id;
    const isAssignmentPricingEnabled = settings?.is_pricing;
    const gracePeriodInMilliseconds = (settings?.grace_period) ? convertDurationStringToMilliseconds(settings?.grace_period) : 0;
    // console.log('grace period: ', gracePeriodInMilliseconds);
    // const [areCountersRunningForSlotBasedLockers, setCountersState] = useState(false);
    const counterRef = useRef(null);
    const [expandedLockerRefId, setExpandedLockerRefId] = useState(null);
    const isInitialGetAssignedLockersDoneRef = useRef(false);
    const [lockerList, setLockerList] = useState([]);
    const [selectedLockerItemForOverstayPayment, setSelectedLockerItemForOverstayPayment] = useState({});
    const [isOpenWithCheckoutAfterOverstayPayment, setIsOpenWithCheckoutAfterOverstayPayment] = useState(false);
    const [selectedBookingSlotForOverstayPayment, setSelectedBookingSlotForOverstayPayment] = useState({});
    const [amountToBePaidForOverstay, setAmountToBePaidForOverstay] = useState(0);
    const [isOverstayPaymentModalVisible, setIsOverstayPaymentModalVisible] = useState(false);
    // const [deviceToken, setDeviceToken] = useState('');
    // const [selectedId, setSelectedId] = useState(null);
    // const [selectedLocker, setSelectedLocker] = useState(null);
    const [isLoading, setLoaderDisabled] = useState(isAssignmentPricingEnabled ? true : false);
    // const [shareLockerList, setshareLockerList] = useState([]);
    // const [listSize, setListSize] = useState(undefined);
    const [active, setActive] = useState(false);
    const [assignLockerror, setAssignLockerror] = useState(false);
    // const [shareLockerror, setShareLockerror] = useState(false);
    const [message, setMessage] = useState({});
    const [originalLockerList, setOriginalLockerList] = useState([]);
    const [returnedLockersList, setReturnedLockersList] = useState([]);
    const [returnFilteredList, setReturnFilteredList] = useState([]);
    const [extraFeatureEnabled, setExtraFeatureEnabled] = useState(false);
    const [hasReturnedLockers, setHasReturnedLockers] = useState(false);
    const [hasDataInSearch, setHasDataInSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [itemPerPage, setItemPerPage] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState(isAssignmentPricingEnabled ? 'Getting assigned lockers' : '');
    const [nextPageUrl, setNextPageUrl] = useState('');
    const [hasNextPageForSearch,setHasNextPageForSearch] = useState(false);
    const [filteredLockers, setFilteredLockers] = useState([]);
    const [isSearchComplete,setIsSearchComplete] = useState(false);
    const [hasPendingLockers, setHasPendingLockers] = useState(0);
    // console.log('settings: ', settings);
    const prevSearchTextRef = useRef();
    const [hasMore, setHasMore] = useState(false);
    const scrollContainerRef = useRef(null);
    const [page,setPage] = useState(1);
    const [count,setCount] = useState(0);
     // Refs to keep track of the latest values
     const hasMoreRef = useRef(hasMore);
     const nextPageUrlRef = useRef(nextPageUrl);
     const pageRef = useRef(page);
     const theme = useTheme();
    const colorScheme = theme.palette.mode;

    const LoadersetON = () => {
        setLoaderDisabled(true);
    };
    const LoadersetOFF = () => {
        setLoaderDisabled(false);
    };

    const toggleLockerAccordionState = (lockerItem) => {
        if (lockerItem.status === RETURN && hasReturnedLockers) {
            return;
        }

        if (lockerItem?.locker_door?.is_blocked) {
            ShowToast('Locker is blocked. Please contact admin.', TOAST_ERROR);
            return;
        }
        
        if (expandedLockerRefId === lockerItem.ref_id) {
            setExpandedLockerRefId(null);
        }
        else {
            setExpandedLockerRefId(lockerItem.ref_id);
        }
    };

    useEffect(() => {
        if ( !isAssignmentPricingEnabled || lockerList.length === 0) {
            return;
        }

        if (counterRef.current) {
            clearInterval(counterRef.current);
        }

        counterRef.current = setInterval(() => {
            const currentTime = moment();

            setLockerList((prevLockerList) => {
                return prevLockerList.map((locker) => {
                    if (locker?.selected_size_pricing?.id && locker?.status === 'assigned') {
                        const assignedDateAndTime = moment(locker?.assigned_date);
                        const usageTimeInMilliseconds = currentTime.diff(assignedDateAndTime);
                        const remainingBookingDurationInMilliseconds = (usageTimeInMilliseconds <= locker.totalBookingDurationInMilliseconds) ? (locker.totalBookingDurationInMilliseconds - usageTimeInMilliseconds) : 0;
                        const overstayDurationInMilliseconds = (usageTimeInMilliseconds > locker.totalBookingDurationInMilliseconds) ? (usageTimeInMilliseconds - locker.totalBookingDurationInMilliseconds) : 0;
                        const isOverstay = overstayDurationInMilliseconds > 0 ? true : false;
                        // devConsoleLog('remaining booking duration in milliseconds: ', remainingBookingDurationInMilliseconds);
                        // devConsoleLog('overstay duration in milliseconds: ', overstayDurationInMilliseconds);
                        // const usageTimeDuration = moment.duration(usageTimeInMilliseconds);
                        const formattedUsageTime = convertMillisecondsToDaysHoursMinutesSecondsFormat(isOverstay ? overstayDurationInMilliseconds : remainingBookingDurationInMilliseconds);

                        // const isOverstay = checkIfOverstay(convertDurationStringToMilliseconds(locker?.selected_size_pricing.duration), gracePeriodInMilliseconds, usageTimeInMilliseconds);

                        return {
                            ...locker,
                            totalUsageTimeInMilliseconds: usageTimeInMilliseconds,
                            remainingBookingDurationInMilliseconds,
                            overstayDurationInMilliseconds,
                            usageTime: formattedUsageTime,//moment(usageTimeInMilliseconds).format('HH:mm:ss'),
                            isOverstay
                        }
                    }

                    return locker;
                });
            });
        }, 1000);

        return () => {
            if (counterRef.current) {
                clearInterval(counterRef.current);
            }
        }
    }, [lockerList.length]);

    const checkIfOverstay = (selectedDurationInMilliseconds, gracePeriodInMilliseconds, usageTimeInMilliseconds) => {
        return (usageTimeInMilliseconds < (selectedDurationInMilliseconds + gracePeriodInMilliseconds)) ? false : true;
    };

    const convertMillisecondsToDaysHoursMinutesSecondsFormat = (milliseconds) => {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        seconds %= 60;
        minutes %= 60;
        hours %= 24;

        const formattedDays = days.toString().padStart(1, '0');
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedDays} d : ${formattedHours} h : ${formattedMinutes} m : ${formattedSeconds} s`;
    };

    const getAssignedLockersAndCheckPaymentStatus = async () => {
        let assignedLockerList = await getAssignedLockers(settings.id);
        devConsoleLog('assigned locker list: ', assignedLockerList);

        const shouldRefetchUpdatedAssignedLockersList = await checkPaymentStatusOfPendingReservationsAndOverstayTransactions(assignedLockerList);
        devConsoleLog('Should refetch updated assigned lockers list?', shouldRefetchUpdatedAssignedLockersList);

        if (shouldRefetchUpdatedAssignedLockersList) {
            assignedLockerList = await getAssignedLockers(settings.id);
        }

        const updatedLockerList = calculateTotalBookingDurationForEachLocker(assignedLockerList);
        devConsoleLog('updated locker list with amount paid and booking duration: ', updatedLockerList);
        setLockerList(updatedLockerList);

        setLoaderDisabled(false);
    };

    const getAssignedLockers = async (assignmentId, searchText = '', pageNumber = 1) => {
        try {
            const getAssignedLockerListResponse = await getAssignedLockerList(assignmentId, searchText, pageNumber);

            if (getAssignedLockerListResponse?.status === SUCCESS) {
                return getAssignedLockerListResponse?.data.length > 0 ? getAssignedLockerListResponse?.data : [];
            }
        } catch (error) {
            console.error('Error while fetching assigned lockers list: ', error);
            return [];
        }
    };

    const checkPaymentStatusOfPendingReservationsAndOverstayTransactions = async (assignedLockerList) => {
        const paymentStatusCheckPromises = [];
        let shouldRefetchUpdatedAssignedLockersList = false;

        for (const locker of assignedLockerList) {
            if (locker.status === 'pending') {
                shouldRefetchUpdatedAssignedLockersList = true;
                paymentStatusCheckPromises.push(checkPaymentStatusAndCancelPendingReservation(locker));
            }
            else if (locker.status === 'assigned') {
                for (const paymentRecord of locker.payment_records) {
                    if ((paymentRecord.payment_type === 'OVERDUE') && (paymentRecord.status !== 'completed')) {
                        shouldRefetchUpdatedAssignedLockersList = true;
                        paymentStatusCheckPromises.push(checkPaymentStatus(paymentRecord.transaction_id, paymentRecord.payment_gateway));
                    }
                };
            }
        };

        if (paymentStatusCheckPromises.length > 0) {
            await Promise.all(paymentStatusCheckPromises);
        }

        return shouldRefetchUpdatedAssignedLockersList;
    };

    const checkPaymentStatusAndCancelPendingReservation = async (locker) => {
        try {
            const isLockerReservationPaymentSuccessful = await checkPaymentStatus(locker.payment_records[0].transaction_id, locker.payment_records[0].payment_gateway);
            // devConsoleLog('is locker reservation payment successful? ', isLockerReservationPaymentSuccessful);

            if (isLockerReservationPaymentSuccessful) {
                return true;
            }
            else if (isLockerReservationPaymentSuccessful === false) {
                const isReservationCancelled = await cancelPendingReservation(locker.ref_id, 'Cancel luggage locker reservation: Unsuccessful payment.');
                
                if (isReservationCancelled) {
                    devConsoleLog('Reservation cancelled for locker: ', locker?.locker_door?.door_no);
                }
                else {
                    devConsoleLog('Unable to cancel reservation for locker: ', locker?.locker_door?.door_no);
                }
            }

            return false;
        } catch (error) {
            console.error();
        }
    };

    const checkPaymentStatus = async (paymentTransactionId, paymentGateway) => {
        try {
            const fetchPaymentStatusResponse = await fetchPaymentStatus(paymentTransactionId, paymentGateway);
            // devConsoleLog('fetch payment status response: ', fetchPaymentStatusResponse);

            if (fetchPaymentStatusResponse?.status === SUCCESS) {
                return fetchPaymentStatusResponse?.data?.is_paid;
            }
        } catch (error) {
            console.error('Error while fetching payment status: ', error);
            return undefined;
        }
    };

    const cancelPendingReservation = async (lockerReferenceId, cancellationReason) => {
        try {
            const cancelReservationResponse = await cancelDropOffTransaction(lockerReferenceId, cancellationReason);

            if (cancelReservationResponse?.status === SUCCESS) {
                return true;
            }
        } catch (error) {
            console.error('Error while cancelling pending reservation: ', error);
            return undefined;
        }
    };

    const calculateTotalBookingDurationForEachLocker = (assignedLockerList) => {
        return assignedLockerList.map((locker) => {
            locker.size_pricing.forEach((slot) => {
                slot.durationInMilliseconds = convertDurationStringToMilliseconds(slot.duration);
            });

            const incrementallySortedBookingSlots = locker?.size_pricing?.slice().sort((a, b) => a.durationInMilliseconds - b.durationInMilliseconds);
            const maxDurationBookingSlot = incrementallySortedBookingSlots[incrementallySortedBookingSlots.length - 1];
            // devConsoleLog('max duration booking slot: ', maxDurationBookingSlot);
            const wasPaymentMadeForMaxDurationBookingSlot = maxDurationBookingSlot.durationInMilliseconds === convertDurationStringToMilliseconds(locker.selected_size_pricing.duration);
            // devConsoleLog('was payment made for max duration booking slot?', wasPaymentMadeForMaxDurationBookingSlot);
            // let totalAmountPaid = 0;
            let totalBookingDurationInMilliseconds = 0;

            // if (wasPaymentMadeForMaxDurationBookingSlot) {
            const totalAmountPaid = locker.payment_records.reduce((accumulatedAmount, paymentRecord) => {
                if ((paymentRecord.payment_type === 'PAYMENT' || paymentRecord.payment_type === 'OVERDUE') && (paymentRecord.status === 'completed')) {
                    return accumulatedAmount + parseFloat(paymentRecord.amount);
                }
                else {
                    return accumulatedAmount;
                }
            }, 0);
            const isTotalAmountPaidGreaterThanMaxDurationBookingSlotPrice = totalAmountPaid > parseFloat(maxDurationBookingSlot.price);

            if (isTotalAmountPaidGreaterThanMaxDurationBookingSlotPrice) {
                const NumberOfMaxDurationBookingSlotsBasedOnTotalAmountPaid = totalAmountPaid / parseFloat(maxDurationBookingSlot.price);
                // devConsoleLog('number of max duration bookings slots the total amount is paid for: ', NumberOfMaxDurationBookingSlotsBasedOnTotalAmountPaid);
                totalBookingDurationInMilliseconds = NumberOfMaxDurationBookingSlotsBasedOnTotalAmountPaid * maxDurationBookingSlot.durationInMilliseconds;
            }
            else {
                const bookingSlotToConsiderBasedOnTotalAmountPaid = locker.size_pricing.find((slot) => totalAmountPaid === parseFloat(slot.price));
                devConsoleLog('booking slot to consider based on total amount: ', bookingSlotToConsiderBasedOnTotalAmountPaid);
                // totalAmountPaid = parseFloat(locker.selected_size_pricing.price);
                totalBookingDurationInMilliseconds = bookingSlotToConsiderBasedOnTotalAmountPaid.durationInMilliseconds;
            }
            devConsoleLog('total amount paid: ', totalAmountPaid);
            devConsoleLog('total booking duration in milliseconds: ', totalBookingDurationInMilliseconds);

            return {
                ...locker,
                totalAmountPaid,
                totalBookingDurationInMilliseconds
            };
        });
    };

    const getLockerInfo = async (searchText,page = 1) => {
        try {
            if (page === 1) {
                setLockerList([]);
                setFilteredLockers([])
            }
            if (settings) {
                try {
                    const assignLockerList = await getAssignedLockerList(settings.id, searchText, page);
                    if (assignLockerList?.status === SUCCESS) {
                        // console.log('assigned locker list: ', assignLockerList?.data);
                        setActive(false);
                        setAssignLockerror(false);
                        if (assignLockerList.next) {
                            setHasMore(true);
                            setNextPageUrl(assignLockerList.next);
                        } else {
                            setHasMore(false);
                            setNextPageUrl('');
                        }
                        setItemPerPage(assignLockerList?.item_per_page);
                        const hasPendingLockers = assignLockerList.data.filter(locker => locker.status === PENDING);
                        setHasPendingLockers(hasPendingLockers);
                        const hasReturnedStatus = assignLockerList.data.some(locker => locker.status === RETURN);
                        const returnedLockers = assignLockerList.data.filter(locker => locker.status === RETURN);
                        setHasReturnedLockers(hasReturnedStatus)
                        setReturnedLockersList(returnedLockers)
                        const assignedLockers = assignLockerList.data.filter(locker => locker.status !== RETURN);
                        setLockerList(prevList => [...prevList, ...assignedLockers]);
                        const extraFeatureEnabled = assignLockerList.data.some(item => item.status ===  PENDING);
                        setExtraFeatureEnabled(extraFeatureEnabled);
                        if ((searchText === undefined || searchText === '') && page === 1) {
                            setCount(assignLockerList?.count)
                            setOriginalLockerList(assignLockerList.data);
                        }
                    } else {
                        setActive(assignLockerList === false);
                        setMessage({ maintext: t('InternalServerText'), subtext: '' });
                        setAssignLockerror(assignLockerList === null);
                    }
                } catch (error) {
                    setMessage({ maintext: t('ServerTimeout') });
                    setAssignLockerror(true);
                    console.error('getMyLockerList error', error);
                }
            }
        } catch (error) {
            console.error('getLockerInfo getMyLockerList try catch = ', error);
        }
    }



    /**
     * Retrieves locker information after performing a search.
     * 
     * @param {string} searchText - The text to search for.
     * @param {number} [page=1] - The page number to retrieve.
     * @returns {Promise<void>} - A promise that resolves when the locker information is retrieved.
     * @throws {Error} - If an error occurs while retrieving the locker information.
     */
    const getLockerInfoAfterSearch = async (searchText, page = 1) => {
        try {
            if (searchText.length > 0) {
                setFilteredLockers([]);
                setReturnFilteredList([]);
            }
            if (settings) {
                const assignLockerList = await getAssignedLockerList(settings.id, searchText, page);
                if (assignLockerList) {
                    setActive(false);
                    setAssignLockerror(false);
                    if (assignLockerList.next) {
                        setHasNextPageForSearch(assignLockerList.next);
                    } else {
                        setHasNextPageForSearch(false);
                    }
                    setIsSearchComplete(true);
                    const assignedLockers = assignLockerList.data.filter(locker => locker.status !== RETURN);
                    setFilteredLockers(assignedLockers);
                    assignLockerList.data.length > 0 && setHasDataInSearch(true);
                    const returnedLockers = assignLockerList.data.filter(locker => locker.status === RETURN);
                    const hasReturnedStatus = assignLockerList?.data.some(locker => locker.status === RETURN);
                    setHasReturnedLockers(hasReturnedStatus)
                    setReturnFilteredList(returnedLockers);
                    const extraFeatureEnabled = assignLockerList.data.some(item => item.status === PENDING);
                    setExtraFeatureEnabled(extraFeatureEnabled);
                } else {
                    setActive(assignLockerList === false);
                    setMessage({ maintext: t('InternalServerText'), subtext: '' });
                    setAssignLockerror(assignLockerList === null);
                }
            }
        } catch (error) {
            setIsSearchComplete(true);
            console.error('getLockerInfo getMyLockerList try catch = ', error);
        }
    }

    /*
    const getSharedLockerInfo = async () => {
        // setSharedList([]);
        setListSize(0);
        try {
            const accessToken = localStorage.getItem('token');
            const domain = localStorage.getItem('domain');
            if (accessToken && domain) {
                try {
                    const data ="" //await getMySharedLockerList(accessToken, domain);
                    if (data) {
                        setShareLockerror(false);
                        const shareSize = data.shared_with_me.length;
                        if (shareSize > 0) {
                            setshareLockerList(data);
                            // setSharedList(data.shared_with_me);
                            setListSize(shareSize);
                        } else {
                            // setSharedList([]);
                            setListSize(0);
                        }
                    } else {
                        setMessage({ maintext: t('InternalServerText'), subtext: '' });
                        setShareLockerror(true);
                        setActive(false);
                    }
                } catch (error) {
                    setMessage({ maintext: t('ServerTimeout') });
                    setShareLockerror(true);
                }
            }
        } catch (error) {
            console.error('getSharedLockerInfo getMySharedLockerList try catch =', error);
        }
    };
    */

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(() => {
            func(...args);
          }, delay);
        };
      };

    const debouncedGetLockerInfo = useCallback(debounce((text) => {
        getLockerInfoAfterSearch(text);
    }, 500), []);

    /**
     * Handles searching for a user in the local locker list.
     */
    const handleSearchUserInLocal = () => {
        try {
            const filtered = originalLockerList.filter((locker) =>
                locker?.client_ref?.toLowerCase().includes(searchText?.toLowerCase()) || locker?.locker_door?.door_no.includes(searchText)
            );
            const filteredLockers = filtered?.filter(locker => locker.status !== RETURN);
            const extraFeatureEnabled = filtered?.some(item => item.status ===  PENDING);
            setExtraFeatureEnabled(extraFeatureEnabled);
            setFilteredLockers(filteredLockers);
            if (filtered.length > 0) {
                setHasDataInSearch(true);
            } else {
                setHasDataInSearch(false);
            }
            const returnedLockers = filtered.filter(locker => locker.status === RETURN);
            const hasReturnedStatus = filtered.some(locker => locker.status === RETURN);
            setHasReturnedLockers(hasReturnedStatus)
            setReturnFilteredList(returnedLockers);
        } catch (error) {
            console.error("MyLockerListPage.js - handleSearchUserInLocal", error)
        }
    }

    useEffect(() => {
        if (isAssignmentPricingEnabled && !isInitialGetAssignedLockersDoneRef.current) {
            isInitialGetAssignedLockersDoneRef.current = true;
            devConsoleLog('Assignment pricing enabled: luggage locker functionality');
            getAssignedLockersAndCheckPaymentStatus();
        }
        else {
            if (prevSearchTextRef.current !== searchText) {
                setHasReturnedLockers(false);
                if (searchText.length > 2) {
                    if(count > itemPerPage){
                        debouncedGetLockerInfo(searchText);
                    }else{
                        handleSearchUserInLocal();
                    }
                } else if (searchText.length === 0) {
                    setPage(1)
                    setIsSearchComplete(false);
                    setHasDataInSearch(false);
                    getLockerInfo();
                }
                prevSearchTextRef.current = searchText;
            }
        }
    }, [searchText]);

    /**
     * Handles the scroll event and loads more locker information if there is more data available.
     */
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
            if (hasMoreRef.current) {
                const nextPage = extractPageNumber(nextPageUrlRef.current);
                setPage(nextPage);
                pageRef.current = nextPage
                getLockerInfo(searchText, nextPage);
            }
        }
    };

    const debouncedHandleScroll = useCallback(debounce(handleScroll, 200), []);

      useEffect(() => {
        hasMoreRef.current = hasMore;
        nextPageUrlRef.current = nextPageUrl;
        pageRef.current = page;
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', debouncedHandleScroll);
        }
        return () => {
          if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', debouncedHandleScroll);
          }
        };
      }, [debouncedHandleScroll,hasMore, nextPageUrl,page]);
    

    const navigateToScanBarCode = () => {
        history.push('/new-reserve-locker', {scan_barcode: true,hasPendingLockers});
    };

    const navigateToScanQRCode = () => {
        history.push('/new-reserve-locker', {scan_qr_code: true, hasPendingLockers});
    };

    const Locker = ({ item, ListType,isReturnAndDropOff }) => {
        if (isAssignmentPricingEnabled) {
            const isSelectedBookingSlotAvailableForLocker = item?.selected_size_pricing?.id ? true : false;
            const isPaymentPendingWhenSelectedBookingSlotIsAvailable = (item?.status === 'pending') ? true : false;

            if (isSelectedBookingSlotAvailableForLocker && isPaymentPendingWhenSelectedBookingSlotIsAvailable) {
                return (
                    <></>
                );
            }
            else {
                return (
                    <LockerItemWithDetails
                        item={item}
                        className='locker-item'
                        usecase={settings.usecase}
                        showBookingDateAndTime={true}
                        showOpenAndCheckoutButtons={true}
                        isLockerAccordionExpanded={expandedLockerRefId === item.ref_id}
                        onLockerAccordionStateToggle={() => toggleLockerAccordionState(item)}
                        LoadersetOFF={LoadersetOFF}
                        openAndCheckoutButtonsHandler={openAndCheckoutButtonsHandler}
                        // openWithAndWithoutCheckout={openWithAndWithoutCheckout}
                        // showOverstayPaymentConfirmationModal={showOverstayPaymentConfirmationModal}
                        makePrePaymentRegistration={makePrePaymentRegistration}
                        setLoader={setLoaderDisabled}
                        isLockerOpening={isLoading}
                        setLoaderMessage={setLoadingMessage}
                    />
                );
            }
        }
        else {
            return (
                <LockerItemWithDetails
                    item={item}
                    className='locker-item'
                    // onPress={setSliderId}
                    usecase={settings.usecase}
                    isLockerAccordionExpanded={expandedLockerRefId === item.ref_id}
                    onLockerAccordionStateToggle={() => toggleLockerAccordionState(item)}
                    // selectSlider={selectSlider}
                    // token={deviceToken}
                    ListType={ListType}
                    // LoadersetON={LoadersetON}
                    LoadersetOFF={LoadersetOFF}
                    makePrePaymentRegistration={makePrePaymentRegistration}
                    isReturnAndDropOff={isReturnAndDropOff}
                    setLoader={setLoaderDisabled}
                    isLockerOpening={isLoading}
                    setLoaderMessage={setLoadingMessage}
                    hasPendingLockers={hasPendingLockers}
                    returnedLockers={returnedLockersList}
                />
            );
        }
    };


    const handleSearchText = (e) => {
        setSearchText(e.target.value.trim())
    }

    const openAndCheckoutButtonsHandler = (isWithCheckout, lockerItem) => {
        if ((lockerItem?.isOverstay) && (lockerItem.overstayDurationInMilliseconds > gracePeriodInMilliseconds)) {
            showOverstayPaymentConfirmationModal(isWithCheckout, lockerItem);
        }
        else {
            openWithAndWithoutCheckout(isWithCheckout, lockerItem);
        }
    }

    const showOverstayPaymentConfirmationModal = (isWithCheckout, lockerItem) => {
        const amountToBePaid = calculateOverstayAmount(lockerItem);
        setAmountToBePaidForOverstay(amountToBePaid);
        setSelectedLockerItemForOverstayPayment(lockerItem);
        setIsOpenWithCheckoutAfterOverstayPayment(isWithCheckout);
        setIsOverstayPaymentModalVisible(true);
    }

    const calculateOverstayAmount = (lockerItem) => {
        const totalUsageTimeInMilliseconds = lockerItem.totalUsageTimeInMilliseconds;
        const selectedBookingSlotDetails = lockerItem.selected_size_pricing;
        const amountAlreadyPaid = lockerItem.totalAmountPaid;
        let amountToBePaid = 0;

        lockerItem.size_pricing.forEach((slot) => {
            slot.durationInMilliseconds = convertDurationStringToMilliseconds(slot.duration);
        });

        const incrementallySortedBookingSlots = lockerItem?.size_pricing?.slice().sort((a, b) => a.durationInMilliseconds - b.durationInMilliseconds);
        const maxDurationBookingSlot = incrementallySortedBookingSlots[incrementallySortedBookingSlots.length - 1];
        // console.log('incrementally sorted booking slots: ', incrementallySortedBookingSlots);
        // console.log('max duration booking slot: ', maxDurationBookingSlot);

        if (totalUsageTimeInMilliseconds > (maxDurationBookingSlot.durationInMilliseconds)) {
            let totalNumberOfMaxDurationSlotsToConsider = parseInt(totalUsageTimeInMilliseconds / maxDurationBookingSlot.durationInMilliseconds);
            const extraDurationBeyondAllMaxDurationsConsidered = totalUsageTimeInMilliseconds % maxDurationBookingSlot.durationInMilliseconds;

            if (extraDurationBeyondAllMaxDurationsConsidered >= gracePeriodInMilliseconds) {
                ++totalNumberOfMaxDurationSlotsToConsider;
            }

            amountToBePaid = (totalNumberOfMaxDurationSlotsToConsider * parseFloat(maxDurationBookingSlot.price)) - parseFloat(amountAlreadyPaid);
            // console.log('amount to pay: ', amountToBePaid);

            setSelectedBookingSlotForOverstayPayment(maxDurationBookingSlot);

            return amountToBePaid;
        }
        else {
            const bookingSlotToConsiderForOverstay = incrementallySortedBookingSlots.find((slot) => totalUsageTimeInMilliseconds < (slot.durationInMilliseconds + gracePeriodInMilliseconds));
            // console.log('booking slot to consider: ', bookingSlotToConsiderForOverstay);
            amountToBePaid = parseFloat(bookingSlotToConsiderForOverstay.price) - parseFloat(amountAlreadyPaid);
            // console.log('amount to pay: ', amountToBePaid);

            setSelectedBookingSlotForOverstayPayment(bookingSlotToConsiderForOverstay);

            return amountToBePaid;
        }
    }

    const renderOverstayPaymentModalContent = () => {
        return (
            <Box className='modal-content'>
                <Typography className='overstay-text'>You've exceeded your booking duration. Please pay excess usage fees to open your locker.</Typography>
                <Box className='info-row'>
                    <Typography className='left-column'>Price:</Typography>
                    <Typography className='right-column'>{amountToBePaidForOverstay}</Typography>
                </Box>
            </Box>
        );
    }

    const makeOverstayPaymentAndThenAccessLocker = async () => {
        // console.log('selected locker: ', selectedLockerItemForOverstayPayment);

        setLoadingMessage('Initiating payment. Please wait');
        setLoaderDisabled(true);

        try {
            const overduePaymentResponse = await overduePayment(selectedLockerItemForOverstayPayment.ref_id, amountToBePaidForOverstay);
            // console.log('overdue payment response: ', overduePaymentResponse);

            if (overduePaymentResponse?.status === SUCCESS) {
                const overstayPrePaymentRegistrationResponse = await makeOverstayPrePaymentRegistration(overduePaymentResponse);
                // console.log('overstay pre payment registration response: ', overstayPrePaymentRegistrationResponse);

                if (overstayPrePaymentRegistrationResponse?.status === SUCCESS) {
                    const lockerData = {
                        reservedLocker: selectedLockerItemForOverstayPayment,
                        assignment: {
                            payment_gateway: selectedLockerItemForOverstayPayment.assignment.payment_gateway
                        },
                        payment_records: [
                            { amount: overstayPrePaymentRegistrationResponse?.data?.data?.amount }
                        ],
                        user: {
                            username: '',
                            email: selectedLockerItemForOverstayPayment.user.email,
                            mobile_number: selectedLockerItemForOverstayPayment.user.mobile_number
                        }
                    };
                    // console.log('locker data for overstay: ', lockerData);

                    if (isOpenWithCheckoutAfterOverstayPayment) {
                        sessionStorage.setItem('isOpenWithCheckoutAfterOverstayPayment', isOpenWithCheckoutAfterOverstayPayment);
                    }
                    storeSecureItemInSpecifiedStorage(SESSION_STORAGE, SS_PAYMENT_GATEWAY, overstayPrePaymentRegistrationResponse?.data?.data?.payment_gateway);
                    storeSecureItemInSpecifiedStorage(SESSION_STORAGE, SS_PAYMENT_GATEWAY_ACCESS_KEY, overstayPrePaymentRegistrationResponse?.data?.data?.payment_gateway_reference);

                    setLoaderDisabled(false);
                    history.push({
                        pathname: '/payment-details',
                        state: {
                            lockerUnitDetails: selectedLockerItemForOverstayPayment,
                            lockerData,
                            // paymentGateway: overstayPrePaymentRegistrationResponse?.data?.data?.payment_gateway,
                            currentReferenceId: overstayPrePaymentRegistrationResponse?.data?.data?.transaction_id,
                            // paymentGatewayAccessKey: overstayPrePaymentRegistrationResponse?.data?.data?.payment_gateway_reference,
                            isOverstayPayment: true,
                            selectedBookingSlotForOverstayPayment
                        }
                    })
                }
            }
        } catch (error) {
            setLoaderDisabled(false);
            console.error('Error during overdue payment: ', error);
        }
    };

    const makeOverstayPrePaymentRegistration = async (overduePaymentResponse) => {
        try {
            const body = {
                "payment_reference_id": overduePaymentResponse?.data?.transaction_id,
                "payment_gateway": overduePaymentResponse?.data?.payment_gateway,
                "locker_bank_id": selectedLockerItemForOverstayPayment?.locker_bank.id,
                "locker_door_number": selectedLockerItemForOverstayPayment?.locker_door.door_no,
                "amount": overduePaymentResponse?.data?.amount
            };

            return await prePaymentRegistration(body);
        } catch (error) {
            console.error('Error while initiating payment for overstay: ', error);
        }
    };

    const openWithAndWithoutCheckout = async (withCheckout, lockerItem) => {
        setLoadingMessage('Opening locker. Please wait.');
        setLoaderDisabled(true);

        try {
            const openLockerResponse = await openLocker(lockerItem?.ref_id);
            if (openLockerResponse.status === SUCCESS) {
                if (withCheckout) {
                    setLoadingMessage('Checkout in progress. Please wait.');
                    const releaseLockerResponse = await releaseLockerOfPickUpUser(lockerItem?.ref_id);

                    if (releaseLockerResponse.status === SUCCESS) {
                        ShowToast(releaseLockerResponse.message, TOAST_SUCCESS);
                    }
                }
                else {
                    ShowToast(openLockerResponse.message, TOAST_SUCCESS);
                }

                setLoaderDisabled(false);

                history.push({
                    pathname: getPathForNextPage(HOME, LOCKER_OPENED),
                    state: {
                        lockerUnitDetails: lockerItem,
                        // openedLocker: lockerItem?.locker_door,
                        is_already_opened_once: withCheckout ? false : true,
                        // ref_id: lockerItem?.ref_id,
                        checkoutTerminologyInsteadOfRelease: true
                    }
                });
            }
        }
        catch (error) {
            setLoaderDisabled(false);
            console.error('Error: ', error);
        }
    }

    const makePrePaymentRegistration = async (lockerData) => {
        LoadersetON();
        setLoadingMessage(t('initiating_transaction_please_wait'));
        const body = {
            "payment_reference_id": lockerData.payment_records[0].transaction_id,
            "payment_gateway": lockerData.payment_records[0].payment_gateway,
            "locker_bank_id": lockerData.locker_bank.id,
            "locker_door_number": lockerData.payment_records[0].locker_door,
            "amount": lockerData.payment_records[0].amount
        }
        const response = await prePaymentRegistration(body);
        // console.log('response: ', response);
        if (response.status === SUCCESS) {
            storeSecureItemInSpecifiedStorage(SESSION_STORAGE, SS_PAYMENT_GATEWAY, lockerData.payment_records[0].payment_gateway);
            storeSecureItemInSpecifiedStorage(SESSION_STORAGE, SS_PAYMENT_GATEWAY_ACCESS_KEY, response.data?.data?.payment_gateway_reference);

            history.push(getPathForNextPage(HOME, PAYMENT_GATEWAY), {
                lockerData: lockerData,
                hasPendingLockers: hasPendingLockers,
                // paymentGateway:lockerData.payment_records[0].payment_gateway,
                currentReferenceId: lockerData.payment_records[0].transaction_id,
                // paymentGatewayAccessKey: response.data?.data?.payment_gateway_reference,
                redirectionPath: "/my-lockers"
            });
            LoadersetOFF();
        }
        else if (response.status === ERROR) {
            ShowToast(t(response.message), TOAST_ERROR);
            LoadersetOFF();
        }
        else if (response.status === REDIRECT) {
            history.push(getPathForNextPage(HOME, HOME), {
                message: t(response.message),
                messageType: TOAST_ERROR
            });
            LoadersetOFF();
        }
    }

    const reserveLockerButtonHandler = () => {
        if (settings.usecase === DYNAMIC_USE_CASE || settings.usecase === LUGGAGE_USE_CASE) {
            getAvailableLockerSizesAndRedirectUser();
        }
        else {
            navigateToScanQRCode();
        }
    };

    const getAvailableLockerSizesAndRedirectUser = async () => {
        try {
            const response = await getAvailableLockerSizesWithUnits(settings?.slug, lockerBankId);
            // console.log('availability response: ', response);
            if (response.status === SUCCESS) {
                const isManualLockerSelectionEnabled = settings?.usecase_settings?.manual_selection_of_locker?.value;
                // console.log('manual selection: ', isManualLockerSelectionEnabled);
                if (response.data.length === 1 && ( !isAssignmentPricingEnabled )) {
                    if (isManualLockerSelectionEnabled) {
                        history.push({
                            pathname: getPathForNextPage(HOME, LOCKER_UNIT_SELECTION),
                            state: {
                                lockerBankId,
                                assignmentSlug: settings?.slug,
                                userId,
                                selectedLockerSize: response.data[0]
                            }
                        });
                    }
                    else {
                        setLoadingMessage('Reserving Locker. Please Wait.');
                        LoadersetON();
                        try {
                            const reserveLockerResponse = await reserveLocker(settings?.slug, lockerBankId, userId, response.data[0].code);
                            if (reserveLockerResponse.status === SUCCESS) {
                                const lockerUnitDetails = {
                                    ref_id: reserveLockerResponse?.data?.transaction_id,
                                    is_already_opened_once: false,
                                    locker_bank: {
                                        name: reserveLockerResponse?.data?.locker_bank_name
                                    },
                                    locker_door: {
                                        door_no: reserveLockerResponse?.data?.door_no
                                    }
                                };

                                LoadersetOFF();
                                history.push({
                                    pathname: getPathForNextPage(HOME, OPEN_LOCKER),
                                    state: {
                                        lockerUnitDetails
                                        // assignedLocker: reserveLockerResponse.data
                                    }
                                });
                            }
                        } catch (error) {
                            LoadersetOFF();
                            console.error('Error while reserving locker: ', error);
                        }
                    }
                }
                else {
                    history.push({
                        pathname: getPathForNextPage(HOME, LOCKER_SIZE_SELECTION),
                        state: {
                            lockerBankId,
                            assignmentSlug: settings?.slug,
                            userId,
                            availableLockerSizes: response.data,
                            isManualLockerSelectionEnabled
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error while fetching available locker sizes: ', error);
        }
    }

    // getAvailableLockerSizesAndRedirectUser();

    return (
        <Box className='my-locker-list-page'>
            <Box className='main-container'>
                {isLoading ? (
                    <Box className='main'>
                        <CircularProgressLoader message={loadingMessage} />
                    </Box>
                ) : (
                    <Box className='main'>
                        {active ? (
                            <Box>
                                <Box className='dashicon' component="img" src={dashIcon} alt="Dash Icon" />
                                <Typography className='assignText'>{t('Tenat_Inactive')}</Typography>
                            </Box>
                        ) : (
                            <>
                                {(lockerList.length > 0 || originalLockerList.length > 0) ? (
                                    <>
                                        <Box className='search-bar-view'>
                                            {(settings?.usecase_settings?.show_search_bar?.value) && (
                                                <CustomSearchBar
                                                    inputId='search-bar-input'
                                                    onChange={handleSearchText}
                                                    placeholder={t('searchLockersByAWB')}
                                                    value={searchText}
                                                    icon={<SearchIcon iconFillColor={colorScheme === 'dark' ? APP_WHITE_COLOR : APP_RICH_BLACK_COLOR} />}
                                                />
                                            )}
                                            {(settings?.usecase_settings?.verify_with_barcode?.value && extraFeatureEnabled) && (
                                                <Box className="barcode-float-btn" onClick={navigateToScanBarCode}>
                                                    <BarcodeIcon/>
                                                </Box>
                                            )}
                                        </Box>
                                        <Grid container className="lockergrid" ref={scrollContainerRef}>
                                            {hasReturnedLockers && (
                                                <Box className="badge-view">
                                                    <BadgeComponent badgeContent={1} primaryText={t('pendingPickups')} secondaryText={t('pleaseFinishPickup')} badgeContainer="badge-pink" />
                                                </Box>
                                            )}
                                            {(searchText == '') ? (
                                                <>
                                                    {returnedLockersList.map(item => (
                                                        <Grid item xs={12} key={item.locker_door.id}>
                                                            <Locker item={item} ListType={'Assign'} isReturnAndDropOff={false} />
                                                        </Grid>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    {(returnFilteredList.length > 0) ? (
                                                        <>
                                                            {returnFilteredList.map(item => (
                                                                <Grid item xs={12} key={item.locker_door.id}>
                                                                    <Locker item={item} ListType={'Assign'} isReturnAndDropOff={false} />
                                                                </Grid>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {!hasDataInSearch && (
                                                                <Typography className='no-locker-data'>{t('searchNotFound')}</Typography> 
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                                
                                            {(hasReturnedLockers && extraFeatureEnabled) && (
                                                <Box className="divider-view">
                                                    <Divider className='divider'/>
                                                    <Box className="badge-view">
                                                        <BadgeComponent badgeContent={2} primaryText={t('dropOff')} badgeContainer="badge-green" />
                                                    </Box>
                                                </Box>
                                            )}
                                            {(searchText == '') ? (
                                                <>
                                                    {lockerList.map(item => (
                                                        <Grid item xs={12} key={item.locker_door.id}>
                                                            <Locker item={item} ListType={'Assign'} isReturnAndDropOff={hasReturnedLockers} />
                                                        </Grid>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    {(filteredLockers.length > 0) ? (
                                                        <>
                                                            {(!hasNextPageForSearch) ? (
                                                                <>
                                                                    {filteredLockers.map(item => (
                                                                        <Grid item xs={12} key={item.locker_door.id}>
                                                                            <Locker item={item} ListType={'Assign'} isReturnAndDropOff={hasReturnedLockers} />
                                                                        </Grid>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <Typography className='request-digits'>{t('requestMoreDigitsText')}</Typography>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(isSearchComplete && !hasReturnedLockers) && (
                                                                <Typography className='no-locker-data'>{t('searchNotFound')}</Typography>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {/*
                                            {shareLockerList.length > 0 && (
                                                <>
                                                    <Typography className='shareText'>{t('sharedWithMeText')}</Typography>
                                                    {shareLockerList.map(item => (
                                                        <Grid item xs={12} key={item.locker_unit_bank}>
                                                            <Locker item={item} ListType={'SHARE'} />
                                                        </Grid>
                                                    ))}
                                                </>
                                            )}
                                            */}
                                        </Grid>
                                        {((settings?.usecase_settings?.verify_with_qr_code?.value && extraFeatureEnabled) || ((settings.usecase === DYNAMIC_USE_CASE || settings.usecase === LUGGAGE_USE_CASE) && (lockerList.length < settings.lockers_allowed))) && (
                                            <Box className="assignNewlocker">
                                                <Box className="floatingBtn" onClick={reserveLockerButtonHandler}>
                                                    <ButtonFloatingIcon />
                                                </Box>
                                            </Box>
                                        )}
                                    </>
                                ) : (
                                    <Box className="noAssignedLockerBlock">
                                        <Box className='dashicon' component="img" src={dashIcon} alt="Dash Icon" />
                                        <Typography className='assignText'>{t('notAssignLockerText')}</Typography>
                                        {settings?.usecase_settings?.show_reserve_locker_btn?.value && (
                                            <ButtonComponent handleClick={reserveLockerButtonHandler} title={t('reserveLockerText')} />
                                        )}
                                    </Box>
                                )}
                            </>
                        )}
                        <CustomDialog
                            dialogId='overstay-payment-modal'
                            dialogVisible={isOverstayPaymentModalVisible}
                            onClose={() => setIsOverstayPaymentModalVisible(false)}
                            handleCancel={() => setIsOverstayPaymentModalVisible(false)}
                            handleAccept={makeOverstayPaymentAndThenAccessLocker}
                            dialogTitle={t('Overstay')}
                            children={renderOverstayPaymentModalContent}
                            buttonOneTitle={t('Cancel')}
                            buttonTwoTitle={t('Pay')}
                            buttonProps={{ buttonOne: {type: BUTTON_WARNING}, buttonTwo: {type: BUTTON_PRIMARY}}}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default MyLockeListPage;