export const QR_CODE_LANDING = 'qrCodeLanding';
export const ACCESS_LOCKER_BANK = 'accessLockerBank';
export const HOME = 'home';
export const ASSIGNMENT_SELECTION = 'assignmentSelection';
export const LOGIN = 'login';
export const VERIFY_OTP = 'verifyOtp';
export const LANDING = 'landing'; // Landing page after user logs in
export const LOCKER_SIZE_SELECTION = 'lockerSizeSelection';
export const LOCKER_UNIT_SELECTION = 'lockerUnitSelection';
export const OPEN_LOCKER = 'openLocker';
export const SWIPE_TO_OPEN_LOCKER = 'swipeToOpenLocker';
export const DROP_AND_PICKUP_PARCEL = 'dropAndPickupParcel';
export const DROP_PARCEL = 'dropParcel';
export const PICKUP_PARCEL = 'pickupParcel'; // This page constant is created to differentiate between drop and pickup. But same drop parcel page is used for pickup.
export const EQUIPMENT_GRID = 'equipmentGrid';
export const EQUIPMENT_DETAILS = 'equipmentDetails';
export const PAYMENT_GATEWAY = 'paymentGateway';
export const EXTERNAL_PAYMENT_GATEWAY = 'externalPaymentGateway'; // External: Url outside current domain
export const SUCCESS_PAGE = 'successPage';
export const CANCEL_OR_ERROR_PAGE = 'cancelOrErrorPage';
export const PAYMENT_DETAILS_VERIFIED = 'paymentDetailsVerified';
export const UNIT_OPENED = 'unitOpened';
export const LOCKER_OPENED = 'lockerOpened';
export const ASSIGNED_LOCKERS = 'assignedLockers';
export const MY_RENTED_PRODUCTS = 'myRentedProducts';
export const CAPTURE_PRODUCT_IMAGE = 'captureProductImage';
export const CAPTURE_EQUIPMENT_IMAGE = 'captureEquipmentImage';
export const PAYMENT_SUCCESSFUL = 'paymentSuccessful';
export const INVOICE = 'invoice';
export const MENU_DRAWER = 'menuDrawer';
export const CUSTOM_DRAWER = 'customDrawer';
export const ABOUT = 'about';
export const TERMS_AND_CONDITIONS = 'termsAndConditions';
export const EXTERNAL_TERMS_AND_CONDITIONS = 'externalTermsAndConditions';
export const PRIVACY_POLICY = 'privacyPolicy';
export const FREQUENTLY_ASKED_QUESTIONS = 'frequentlyAskedQuestions';
export const CONTACT_US = 'contactUs';
export const CHAT_SUPPORT = 'chatSupport';
export const SUPPORT = 'support';
export const CONNECT_MOBILE = 'connectMobile';
export const LOGOUT = 'logout';