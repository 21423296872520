import { useEffect, useState} from "react";
import i18n from "i18next";
import {initReactI18next, useTranslation} from "react-i18next";
import HTTPApi from "i18next-http-backend";
import {BrowserRouter} from "react-router-dom";
import {DEFAULT_LANGUAGE_ENGLISH} from "./assets/constants/LanguageOptions";
import {initializeI18nAndThenAppendCustomerSpecificStylesheet, appendCustomerSpecificStylesheet, clearLocalStorageForUser, retrieveISOCodeOfPreviouslySelectedLanguageFromLocalStorage} from "./lib/Utils";
import RouterComponent from "./components/RouterComponent";
import ConnectionLost from "./components/ConnectionLost";
import { ThemeProvider,createTheme } from "@mui/material/styles";
import CssBaseLine from '@mui/material/CssBaseline';
import { APP_DARK_PURPLE_COLOR, APP_ENGLISH_VIOLET_COLOR, APP_GHOST_WHITE_COLOR, APP_WHITE_COLOR } from "./assets/constants/Colors";
import { useMediaQuery } from "@mui/material";
import moment from "moment";
import { IS_LOGGED_IN, LOCAL_STORAGE, LS_CUSTOMER, REFRESH_TOKEN_EXPIRY_TIME, LS_PAGE_FLOW } from "./assets/constants/BrowserStorageKeys";
import { getSecureItemFromSpecificStorage, storeSecureItemInSpecifiedStorage } from "./lib/BrowserStorageAccessMiddleware";
import ShowToast from "./components/ToastComponent";
import { DARK, LIGHT, TOAST_ERROR, HIVEBOARD, DUROLT, GOLDGYM, BLUEDART } from "./assets/constants/Constants";
import CircularProgressLoader from "./components/CircularProgressLoader";

// window.CUSTOMER 
          // = HIVEBOARD;
          // = DUROLT;
          // = BLUEDART;
          // = GOLDGYM;
// const customerNameLowerCase = (window.CUSTOMER).toLowerCase();

// i18n.use(initReactI18next).use(HTTPApi).init({
//   lng: retrieveISOCodeOfPreviouslySelectedLanguageFromLocalStorage(),
//   fallbackLng: DEFAULT_LANGUAGE_ENGLISH.value,
//   backend: {
//     loadPath : loadPathWithNamespace
//   },
//   interpolation: {
//     escapeValue: false
//   },
//   ns: [customerNameLowerCase, 'translation'],
//   defaultNS: customerNameLowerCase,
//   fallbackNS: 'translation',
//   returnObjects : true,
// });

// function loadPathWithNamespace(lng, namespace) {
//   let path = `/assets/locales/${lng}/translation.json`;
//   switch (namespace[0]) {
//     case customerNameLowerCase:
//       // path = `/assets/locales/${lng}/${customerNameLowerCase}.json`;
//       path = `https://pwadev.s3.ap-south-1.amazonaws.com/brand/${customerNameLowerCase}/${customerNameLowerCase}.json`;
//       break;
//     default:
//       break;
//   }
//   return path;
// }

initializeI18nAndThenAppendCustomerSpecificStylesheet();
// appendCustomerSpecificStylesheet();

const App = () =>  {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showConnectionLost, setShowConnectionLost] = useState(!navigator.onLine);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [showLoader, setShowLoader] = useState(false);
  const { t } = useTranslation();

  const handleOnlineStatus = () => setIsOnline(true);
  const handleOfflineStatus = () => {
    setIsOnline(false);
    setShowConnectionLost(true);
  };

  const handleBackButton = (event) => {
    const currentPage = window.location.pathname;
    if (currentPage === '/locker-size') {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', handleBackButton);
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);
    window.addEventListener('click', checkLoginAndTokenExpiry);

    return () => { // Cleanup function for event listeners
      window.removeEventListener('popstate', handleBackButton);
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOfflineStatus);
      window.removeEventListener('click', checkLoginAndTokenExpiry);
    };
  }, []); // Empty dependency array to run effect only once


  const darkTheme = createTheme({
    palette: {
      mode: prefersDarkMode ? DARK : LIGHT, // Handle dark mode state on toggle
      background: {
        default: prefersDarkMode ? APP_DARK_PURPLE_COLOR : APP_WHITE_COLOR,
        primary: prefersDarkMode ? APP_ENGLISH_VIOLET_COLOR : APP_WHITE_COLOR,

      },
      text : {
        primary: prefersDarkMode ? APP_WHITE_COLOR : APP_DARK_PURPLE_COLOR, 
        secondary : prefersDarkMode ? APP_DARK_PURPLE_COLOR : APP_WHITE_COLOR
      },
    }
  });

  // if( t("css") !== "css" ){
  //   var head = document.head;
  //   var link = document.createElement("link");
  //   link.type = "text/css";
  //   link.rel = "stylesheet";
  //   link.href = t("css");
  //   head.appendChild(link);
  // }else{
  //   require('./assets/css/durolt.css');
  // }


  const checkLoginAndTokenExpiry = async () => {
    const isLoggedIn = getSecureItemFromSpecificStorage(LOCAL_STORAGE,IS_LOGGED_IN);
    const refreshExpiryAt = await getSecureItemFromSpecificStorage(LOCAL_STORAGE, REFRESH_TOKEN_EXPIRY_TIME);
    const now = moment.utc();
    const refreshTokenExpiryTime = moment.utc(refreshExpiryAt);
  
    if (isLoggedIn && now.isAfter(refreshTokenExpiryTime)) {
        clearLocalStorageForUser();
        ShowToast(t('sessionExpired'), TOAST_ERROR);
        setShowLoader(true);
        setTimeout(() => {
            setShowLoader(false);
            window.location.href = '/';
        }, 3000); // Adjust the delay as necessary (3000ms = 3 seconds)
    }
  };
  

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseLine />
      {isOnline ? (
        <BrowserRouter>
          <RouterComponent theme={darkTheme} />
        </BrowserRouter>
      ) : (
        showConnectionLost && <ConnectionLost />
      )}
      {showLoader && <CircularProgressLoader message={t('redirectToLogin')} />}
    </ThemeProvider>
  );
}

export default App;

